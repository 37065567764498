<template>
  <div class="tokyo_tm_contact ">
    <div class="tokyo_tm_title">
      <div class="title_flex">
        <div class="left">
          <span>Contacto</span>
          <h3>Contacto Daniel Martín Díaz</h3>
        </div>
      </div>
    </div>
    <!-- End Title -->

    <div class="map_wrap">
      <iframe
        id="gmap_canvas"
        src="https://maps.google.com/maps?width=100%25&amp;height=600&amp;hl=es&amp;q=Plaza%20Joaqu%C3%ADn%20Costa,%20n%C2%BA2+(Daniel%20Mart%C3%ADn%20)&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
        frameborder="0"
        scrolling="no"
        marginheight="0"
        marginwidth="0"
      ></iframe>
    </div>
    <!-- End Google Map -->
    <div>
      <p><i class="fa-solid fa-envelope"></i> Email personal: dmartindiaz3@gmail.com</p>
      <p><i class="fa-solid fa-envelope"></i> Email profesional: conecta2s@conecta-2s.com</p>
      <p><i class="fa-solid fa-phone"></i> Teléfono: +34 663 56 18 97</p>
      <p><i class="fa-brands fa-linkedin"></i> LinkedIn: <a href="https://www.linkedin.com/public-profile/settings?trk=d_flagship3_profile_self_view_public_profile">LinkedIn</a></p>
    </div>

    <!-- END FIELDS -->
  </div>
</template>

<script>
  export default {
    data() {
      return {
        formData: {
          name: "",
          email: "",
          message: "",
        },
      };
    },
    methods: {
      onSubmit() {
        console.log(this.formData);
      },
    },
  };
</script>
