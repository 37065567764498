<template>
<div>
    <div class="text-sm breadcrumbs mt-32 xl:mt-5">
  <ul>
    <li>
      <a>
        <p>Curriculum <i class="fa-solid fa-house"></i></p>
      </a>
    </li> 
    <li>
      <a>
        <p>Sobre mi <i class="fa-solid fa-user"></i></p>
      </a>
    </li>
  </ul>
</div>
  <div class="tokyo_tm_about">
    <div class="about_image -mt-36 xl:-mt-20">
      <img src="../assets/img/vuereact.jpg" alt="about" />
    </div>
    <!-- End .about_image -->

    <div class="description">
      <h3 class="name text-primary"><i class="fa-solid fa-user-tie"></i> Daniel Martín Díaz</h3>
      <div class="description_inner">
        <div class="left hidden">
          <p>
            Amante de la tecnología desde que nací y apasionado de la psicología humana del consumo. Creativo, emprendedor, en constante busqueda de objetivos y cooperativo. Especializado en JavaScript y NodeJS. 
          </p>
          <div class="tokyo_tm_button">
            <button class="ib-button" @click="showModal">Ver especialidades</button>
          </div>
        </div>
        <!-- End .left -->

        <div class="right">
          <ul>
            <li>
              <p><span>Edad:</span> 30</p>
            </li>
            <li>
              <p><span>Dirección:</span> La unión, Murcia.</p>
            </li>
            <li>
              <p>
                <span>Email:</span
                ><a href="mailto:dmartindiaz3@gmail.com"> dmartindiaz3@gmail.com</a>
              </p>
            </li>
            <li>
              <p>
                <span>Teléfono:</span
                ><a href="tel:+770221770505"> +34 663 56 18 97</a>
              </p>
            </li>
            <li>
              <p><span>Freelance: </span> Disponible</p>
            </li>
          </ul>
          <!-- End ul -->
        </div>
        <!-- End .right -->
      </div>

    <h3 class="name text-primary mt-10"><i class="fa-solid fa-user-tie"></i> Frameworks dominados</h3>
          <div class="description_inner">
        <div class="left hidden">
          <p>
            Amante de la tecnología desde que nací y apasionado de la psicología humana del consumo. Creativo, emprendedor, en constante busqueda de objetivos y cooperativo. Especializado en JavaScript y NodeJS. 
          </p>
        </div>
        <!-- End .left -->

        <div class="right">
          <ul>
            <li>
              <p><span>VueJS:</span> 3 años experiencia</p>
              <div class="flex flex-row mt-1 mb-4">
                <i class="fa-solid fa-star text-warning"></i>
                <i class="fa-solid fa-star text-warning"></i>
                <i class="fa-solid fa-star text-warning"></i>
                <i class="fa-solid fa-star text-warning"></i>
                <i class="fa-solid fa-star text-warning"></i>
              </div>
            </li>
            <li>
              <p><span>Ionic:</span> 3 años experiencia</p>
              <div class="flex flex-row mt-1 mb-4">
                <i class="fa-solid fa-star text-warning"></i>
                <i class="fa-solid fa-star text-warning"></i>
                <i class="fa-solid fa-star text-warning"></i>
                <i class="fa-solid fa-star text-warning"></i>
                <i class="fa-solid fa-star text-warning"></i>
              </div>
            </li>
            <li>
              <p><span>React:</span> 1 año de experiencia</p>
              <div class="flex flex-row mt-1 mb-4">
                <i class="fa-solid fa-star text-warning"></i>
                <i class="fa-solid fa-star text-warning"></i>
                <i class="fa-solid fa-star text-warning"></i>
                <i class="fa-solid fa-star text-warning"></i>
                <i class="fa-solid fa-star text-secondary"></i>
              </div>
            </li>
            <li>
              <p><span>Electron:</span> 1 año de experiencia</p>
              <div class="flex flex-row mt-1 mb-4">
                <i class="fa-solid fa-star text-warning"></i>
                <i class="fa-solid fa-star text-warning"></i>
                <i class="fa-solid fa-star text-warning"></i>
                <i class="fa-solid fa-star text-warning"></i>
                <i class="fa-solid fa-star text-secondary"></i>
              </div>
            </li>
            <li>
              <p><span>Wordpress:</span> 5 años experiencia</p>
              <div class="flex flex-row mt-1 mb-4">
                <i class="fa-solid fa-star text-warning"></i>
                <i class="fa-solid fa-star text-warning"></i>
                <i class="fa-solid fa-star text-warning"></i>
                <i class="fa-solid fa-star text-warning"></i>
                <i class="fa-solid fa-star text-warning"></i>
              </div>
            </li>
          </ul>
                    <div class="tokyo_tm_button">
            <button class="ib-button" @click="showModal">Más tecnologías</button>
          </div>
          <!-- End ul -->
        </div>
        <!-- End .right -->
      </div>
      
      
      <h3 class="text-2xl font-bold mt-10 text-primary"><i class="fa-solid fa-building-columns"></i> Experiencia profesional.</h3>
      <div class="flex flex-col text-left mt-5">
          <h4 class="italic">FRONT END DEVELOPER FREELANCE.</h4>
          <h4 class="text-sm italic"><i class="fa-solid fa-calendar-days"></i> (2022 - actualidad)</h4>
          <div class="px-5 mt-2 text-sm">
            <p><i class="fa-solid fa-square-check"></i> Front end developer Vue, React, Ionic y Electron.</p>
            <p><i class="fa-solid fa-square-check"></i> Desarrollador web.</p>
            <p><i class="fa-solid fa-square-check"></i> Desarrollo de microservicios y automatización de procesos de back-end con NodeJS.</p>
          </div>
      </div>
      <div class="flex flex-col text-left mt-5">
          <h4 class="italic">TI & Desarrollador Front-End - Cardiosalus</h4>
          <h4 class="text-sm italic"><i class="fa-solid fa-calendar-days"></i> (2020 - 2022)</h4>
          <div class="px-5 mt-2 text-sm">
            <p><i class="fa-solid fa-square-check"></i> Mantenimiento de equipo informático.</p>
            <p><i class="fa-solid fa-square-check"></i> Desarrollo de software médico.</p>
            <p><i class="fa-solid fa-square-check"></i> Desarrollo de software atención al cliente.</p>
            <p><i class="fa-solid fa-square-check"></i> Desarrollador web.</p>
            <p><i class="fa-solid fa-square-check"></i> Automatización de procesos.</p>
          </div>
      </div>
      <div class="flex flex-col text-left mt-5">
          <h4 class="italic">Decathon Cartagena - Responsable de sección.</h4>
          <h4 class="text-sm italic"><i class="fa-solid fa-calendar-days"></i> (2015 - 2020)</h4>
          <div class="px-5 mt-2 text-sm">
            <p><i class="fa-solid fa-square-check"></i> Gestión, control y análisis de cifras económicas.</p>
            <p><i class="fa-solid fa-square-check"></i> Creación, dirección y animación de proyectos.</p>
            <p><i class="fa-solid fa-square-check"></i> Gestión, control y análisis de espacios comerciales propios.</p>
            <p><i class="fa-solid fa-square-check"></i> Gestión, control, análisis de espacios comerciales comunes.</p>
            <p><i class="fa-solid fa-square-check"></i> RRSS y comunicación omnicanal con clientes segmentados.</p>
            <p><i class="fa-solid fa-square-check"></i> Análisis, conocimiento e intercambio de los distintos productos con las marcas.</p>
          </div>
      </div>

      <h3 class="text-2xl font-bold mt-10 text-primary"><i class="fa-solid fa-graduation-cap"></i> Titulación.</h3>
      <div class="flex flex-col text-left mt-5">
          <h4 class="italic">Técnico superior en sistemas de telecomunicaciones e informáticos.</h4>
          <h4 class="text-sm italic"><i class="fa-solid fa-calendar-days"></i> (2013 - 2015)</h4>
          <h4 class="italic mt-5">Técnico superior en Marketing y Publicidad.</h4>
          <h4 class="text-sm italic"><i class="fa-solid fa-calendar-days"></i> (2018 - 2020)</h4>
      </div>

      <h3 class="text-2xl font-bold mt-10 text-primary hidden"><i class="fa-solid fa-graduation-cap"></i> Estudios no oficiales.</h3>
      <div class="flex flex-col text-left mt-5 hidden">
          <h4 class="text-gray-800 italic text-sm mt-2">De 0 a Experto en JavaScript. - Udemy</h4>
          <h4 class="font-bold text-xs italic"><i class="fa-solid fa-calendar-days"></i> (2019)</h4>
          <h4 class="text-gray-800 italic text-sm mt-2">Adobe illustrator y Adobe photoshop - Udemy</h4>
          <h4 class="font-bold text-xs italic"><i class="fa-solid fa-calendar-days"></i> (2019)</h4>
          <h4 class="text-gray-800 italic text-sm mt-2">Adobe after effects y Adobe premier. - Udemy</h4>
          <h4 class="font-bold text-xs italic"><i class="fa-solid fa-calendar-days"></i> (2019)</h4>
          <h4 class="text-gray-800 italic text-sm mt-2">Google Ads de 0. - Udemy</h4>
          <h4 class="font-bold text-xs italic"><i class="fa-solid fa-calendar-days"></i> (2020)</h4>
          <h4 class="text-gray-800 italic text-sm mt-2">FB Ads y Comunicación corporativa. - Udemy</h4>
          <h4 class="font-bold text-xs italic"><i class="fa-solid fa-calendar-days"></i> (2020)</h4>
          <h4 class="text-gray-800 italic text-sm mt-2">Vue y React. - Udemy</h4>
          <h4 class="font-bold text-xs italic"><i class="fa-solid fa-calendar-days"></i> (2020)</h4>
          <h4 class="text-gray-800 italic text-sm mt-2">De 0 a Experto en Java. - Udemy</h4>
          <h4 class="font-bold text-xs italic"><i class="fa-solid fa-calendar-days"></i> (2022)</h4>
          <h4 class="text-gray-800 italic text-sm mt-2">Android Studio con Java. - Udemy</h4>
          <h4 class="font-bold text-xs italic"><i class="fa-solid fa-calendar-days"></i> (actualidad)</h4>
      </div>
    </div>

    <!-- Start Modalbox -->
    <transition name="fade">
      <!-- Modal -->
      <div
        :class="{ 'modal-mask': isActive }"
        id="modal"
        @click="closeModal"
        v-if="isVisible"
      >
        <div
          class="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-user"
          @click.stop
        >
          <div class="modal-content">
            <div class="tokyo_tm_modalbox_about">
              <div class="close" @click="closeModal">
                <img
                  class="svg"
                  src="../assets/img/svg/cancel.svg"
                  alt="cancel-img"
                />
              </div>
              <!-- End .close -->
              <div class="my_box">
                <div class="left">
                  <div class="about_title">
                    <h3>+ tecnologías</h3>
                  </div>
                  <!-- End .abut_title -->
                  <div class="flex flex-col mt-5">
                    <div class="flex flex-row justify-between w-full">
                      <p>NodeJS</p>
                      <p>100%</p>
                    </div>
                    <progress class="progress" value="100" max="100"></progress>
                  </div>
                  <div class="flex flex-col mt-5">
                    <div class="flex flex-row justify-between w-full">
                      <p>Express</p>
                      <p>85%</p>
                    </div>
                    <progress class="progress" value="85" max="100"></progress>
                  </div>
                  <div class="flex flex-col mt-5">
                    <div class="flex flex-row justify-between w-full">
                      <p>Tailwind CSS</p>
                      <p>100%</p>
                    </div>
                    <progress class="progress" value="100" max="100"></progress>
                  </div>
                  <div class="flex flex-col mt-5">
                    <div class="flex flex-row justify-between w-full">
                      <p>Boostrap</p>
                      <p>75%</p>
                    </div>
                    <progress class="progress" value="75" max="100"></progress>
                  </div>
                  <div class="flex flex-col mt-5">
                    <div class="flex flex-row justify-between w-full">
                      <p>NPM</p>
                      <p>60%</p>
                    </div>
                    <progress class="progress" value="60" max="100"></progress>
                  </div>
                  <!-- End .tokyo_progress -->
                </div>
                <!-- End .left -->
                <div class="right">
                  <div class="about_title">
                    <h3></h3>
                  </div>
                  <div class="flex flex-col mt-5">
                    <div class="flex flex-row justify-between w-full">
                      <p>Firebase</p>
                      <p>100%</p>
                    </div>
                    <progress class="progress" value="100" max="100"></progress>
                  </div>
                  <div class="flex flex-col mt-5">
                    <div class="flex flex-row justify-between w-full">
                      <p>MongoDB</p>
                      <p>85%</p>
                    </div>
                    <progress class="progress" value="90" max="100"></progress>
                  </div>
                  <div class="flex flex-col mt-5">
                    <div class="flex flex-row justify-between w-full">
                      <p>SQL con Sequalize</p>
                      <p>90%</p>
                    </div>
                    <progress class="progress" value="100" max="100"></progress>
                  </div>
                  <div class="flex flex-col mt-5">
                    <div class="flex flex-row justify-between w-full">
                      <p>Java</p>
                      <p>60%</p>
                    </div>
                    <progress class="progress" value="90" max="100"></progress>
                  </div>
                  <div class="flex flex-col mt-5">
                    <div class="flex flex-row justify-between w-full">
                      <p>Linux, Windows server</p>
                      <p>90%</p>
                    </div>
                    <progress class="progress" value="95" max="100"></progress>
                  </div>
                </div>
                <!-- End .right -->
              </div>
              <!-- End .counter -->
              <!-- End .partner -->
            </div>
            <!-- End .tokyo_tm_modalbox_about -->
          </div>
        </div>
      </div>
    </transition>
    <!-- End Modalbox -->
  </div>
</div>
</template>

<script>
export default {
  data() {
    return {
      isActive: false,
      isVisible: false,
    };
  },
  methods: {
    showModal: function() {
      this.isActive = true;
      this.isVisible = true;
    },
    closeModal: function() {
      this.isActive = false;
      this.isVisible = false;
    },
  },
};
</script>

<style lang="scss"></style>
