<template>
<div>
    <div class="text-sm breadcrumbs xl:mt-5">
    <ul>
      <li>
        <a>
          <p>Curriculum <i class="fa-solid fa-house"></i></p>
        </a>
      </li> 
      <li>
        <a>
          <p>Inicio <i class="fa-solid fa-house"></i></p>
        </a>
      </li>
    </ul>
  </div>
  <div class="tokyo_tm_home">
    <div class="home_content">
      <div class="avatar">
        <div
          class="image avatar_img"
          :style="{ backgroundImage: 'url(' + src1 + ')' }"
        ></div>
      </div>
      <!-- End .avatar -->
      <div class="details">
        <h3 class="name">Daniel Martín</h3>
        <p class="job text-2xl font-bold -mt-6">
          FRONT-END DEVELOPER
        </p>
        <div class="flex flex-row text-2xl items-center justify-center xl:justify-start">
          <i class="fa-brands fa-js mr-2"></i>
          <i class="fa-brands fa-node mr-2"></i>
          <i class="fa-brands fa-vuejs mr-2"></i>
          <i class="fa-brands fa-react mr-2"></i>
          <i class="fa-brands fa-npm mr-2"></i>
          <i class="fa-brands fa-wordpress mr-2"></i>
          <i class="fa-brands fa-java mr-2"></i>
        </div>
      </div>
      <!-- End .details -->
    </div>
  </div>
</div>
</template>

<script>
  export default {
    data() {
      return {
        src1: require("../assets/img/foto1.jpg"),
      };
    },
  };
</script>

<style lang="scss"></style>
