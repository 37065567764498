<template>
<div>
      <div class="text-sm breadcrumbs mt-32 xl:mt-5">
  <ul>
    <li>
      <a>
        <p>Curriculum <i class="fa-solid fa-house"></i></p>
      </a>
    </li> 
    <li>
      <a>
        <p>Portfolio <i class="fa-solid fa-signature"></i></p>
      </a>
    </li>
  </ul>
</div>
    <div class="tabs tabs-boxed w-max mt-5">
      <a v-on:click="view(`vue`)" id = "vuebuttom" class="tab tab-active">VueJS</a>
      <a v-on:click="view(`wordpress`)" id = "wordpressbuttom" class="tab">Wordpress</a> 
      <a v-on:click="view(`react`)" id = "reactbuttom" class="tab">React</a>
    </div>
    <div id = "vue" class="grid sm:grid-cols-2 lg:grid-cols-3 grid-cols-1 gap-4 mt-5 xl:mt-5 pb-10 animate__animated animate__backInRight">
      <div class="card bg-base-100 shadow-xl">
        <figure><img src="../assets/img/cara1.jpg" alt="Shoes"/></figure>
        <div class="card-body">
          <h2 class="card-title text-primary">Padelfy</h2>
          <p>Software de gestión y administración de pistas de pádel</p>
          <p class="text-xs font-bold text-black">Usuario: padelfy@padelfy.com</p>
          <p class="text-xs font-bold text-black">Pass: adminadmin</p>
          <div class="card-actions justify-end">
            <a class="btn btn-primary" href="https://padelfy.net/" target="_blank">Visitar página</a>
          </div>
        </div>
      </div>
      <div class="card bg-base-100 shadow-xl">
        <figure><img src="../assets/img/doota.jpg" alt="Shoes"/></figure>
        <div class="card-body">
          <h2 class="card-title text-primary">doota.</h2>
          <p>Gestión de documentación, incidencias y clientes para gestoría.</p>
          <p class="text-xs text-black font-bold">Usuario: usuario1@doota.com</p>
          <p class="text-xs text-black font-bold">Pass: adminadmin</p>
          <div class="card-actions justify-end">
            <a class="btn btn-primary" href="https://doota.conecta-2s.com/" target="_blank">Visitar demo</a>
          </div>
        </div>
      </div>
      <div class="card bg-base-100 shadow-xl">
        <figure><img src="../assets/img/molly.jpg" alt="Shoes"/></figure>
        <div class="card-body">
          <h2 class="card-title text-primary">Molly.</h2>
          <p>Software de gestión y administración de peluquerias.</p>
          <p class="text-xs text-black font-bold">Usuario: admin@admin.com</p>
          <p class="text-xs text-black font-bold">Pass: Murcia2023</p>
          <p></p>
          <p></p>
          <div class="card-actions justify-end flex flex-row">
            <a class="btn btn-primary" href="https://hairish.conecta-2s.com/" target="_blank">Visitar demo</a>
          </div>
        </div>
      </div>
      <div class="card bg-base-100 shadow-xl">
        <figure><img src="../assets/img/appcardiosalus.jpg" alt="Shoes"/></figure>
        <div class="card-body">
          <h2 class="card-title text-primary">Cardiosalus Web y APP.</h2>
          <p>Portal paciente y citas médicas para clientes clinica privada.</p>
          <p class="text-xs text-black font-bold">Usuario: mimail@mimail.com</p>
          <p class="text-xs text-black font-bold">Pass: adminadmin</p>
          <div class="card-actions justify-end">
            <a class="btn btn-primary" href="https://cardiosalus.conecta-2s.com/" target="_blank">Visitar web</a>
          </div>
        </div>
      </div>
      <div class="card bg-base-100 shadow-xl">
        <figure><img src="../assets/img/newlogo.png" alt="Shoes"/></figure>
        <div class="card-body">
          <h2 class="card-title text-primary">Tareator APP.</h2>
          <p>App de creación y seguimiento de tareas. Creación de equipos de trabajo y seguimiento de tareas en equipo</p>
          <div class="card-actions justify-end flex flex-row">
            <a class="btn btn-primary" href="https://www.conecta-2s.com/es/tareator-app/" target="_blank">Visitar web</a>
          </div>
        </div>
      </div>
      <div class="card bg-base-100 shadow-xl">
        <figure><img src="../assets/img/logokont.jpg" alt="Shoes"/></figure>
        <div class="card-body">
          <h2 class="card-title text-primary">Kont APP.</h2>
          <p>Creación de facturación simple para autonomos y pymes.</p>
          <div class="card-actions justify-end flex flex-row">
            <a class="btn btn-primary" href="https://www.conecta-2s.com/es/kont-app/" target="_blank">Visitar web</a>
          </div>
        </div>
      </div>
      <div class="card bg-base-100 shadow-xl">
        <figure><img src="../assets/img/icon.png" alt="Shoes"/></figure>
        <div class="card-body">
          <h2 class="card-title text-primary">Fxpro.</h2>
          <p>App de suplementación deportiva.</p>
          <div class="card-actions justify-end flex flex-row">
            <a class="btn btn-primary" href="https://play.google.com/store/apps/details?id=fxpro.conecta2s.app" target="_blank">Descargar APP</a>
          </div>
        </div>
      </div>
    </div>
    <div id = "react" class="grid sm:grid-cols-2 lg:grid-cols-3 grid-cols-1 gap-4 mt-5 xl:mt-5 pb-10 hidden animate__animated animate__backInRight">
      <div class="card bg-base-100 shadow-xl">
        <figure><img src="../assets/img/taskapp.png" alt="Shoes"/></figure>
        <div class="card-body">
          <h2 class="card-title text-primary">Task Manager</h2>
          <p>Web app sencilla de gestión de proyectos y tareas realizado en React</p>
          <div class="card-actions justify-end">
            <a class="btn btn-primary" href="https://proyectapp.conecta-2s.com/" target="_blank">Ver app</a>
          </div>
        </div>
      </div>
    </div>
    <div id = "wordpress" class="grid sm:grid-cols-2 lg:grid-cols-3 grid-cols-1 gap-4 mt-5 xl:mt-5 pb-10 animate__animated animate__backInRight hidden">
        <div class="card bg-base-100 shadow-xl">
          <figure><img src="https://cardiosalus.com/cms/wp-content/uploads/2021/05/UROLOGIA3.jpg" alt="Shoes"/></figure>
          <div class="card-body">
            <h2 class="card-title text-primary">Cardiosalus</h2>
            <p>Web clínica privada Murcia.</p>
            <div class="card-actions justify-end">
              <a class="btn btn-primary" href="https://cardiosalus.com/" target="_blank">Visitar página</a>
            </div>
          </div>
        </div>

        <div class="card bg-base-100 shadow-xl">
          <figure><img src="https://fxpronutrition.com/wp-content/uploads/2022/01/fxpronutritionlogoazul.png" alt="Shoes"/></figure>
          <div class="card-body -mt-24">
            <h2 class="card-title text-primary">FXPRO Nutrition</h2>
            <p>Tienda de suplementación deportiva.</p>
            <div class="card-actions justify-end">
              <a class="btn btn-primary" href="https://fxpronutrition.com/" target="_blank">Visitar página</a>
            </div>
          </div>
        </div>
                <div class="card bg-base-100 shadow-xl">
          <figure><img src="https://cdn.domestika.org/c_fit,dpr_auto,f_auto,q_80,t_base_params,w_820/v1611665600/content-items/006/882/165/cardiosalus-branding-5-original.jpg?1611665600" alt="Shoes"/></figure>
          <div class="card-body">
            <h2 class="card-title text-primary">Cardiosalus sport</h2>
            <p>Web clínica deportiva en Murcia.</p>
            <div class="card-actions justify-end">
              <a class="btn btn-primary" href="https://cardiosalussport.com/" target="_blank">Visitar página</a>
            </div>
          </div>
        </div>
                        <div class="card bg-base-100 shadow-xl">
          <figure><img src="https://imageio.forbes.com/specials-images/imageserve/6200b0dddcf32d3be937fa84/0x0.jpg?format=jpg&width=1200" alt="Shoes"/></figure>
          <div class="card-body">
            <h2 class="card-title text-primary">Conecta-2s</h2>
            <p>Web desarrollo y servicios de TI.</p>
            <div class="card-actions justify-end">
              <a class="btn btn-primary" href="https://conecta-2s.com/" target="_blank">Visitar página</a>
            </div>
          </div>
        </div>
                        <div class="card bg-base-100 shadow-xl">
          <figure><img src="https://i.ytimg.com/vi/BvU-LNfo0Xc/maxresdefault.jpg" alt="Shoes"/></figure>
          <div class="card-body">
            <h2 class="card-title text-primary">Padelfy</h2>
            <p>Software de gestión de reservas.</p>
            <div class="card-actions justify-end">
              <a class="btn btn-primary" href="https://padelfy.net/" target="_blank">Visitar página</a>
            </div>
          </div>
        </div>
        <div class="card bg-base-100 shadow-xl">
          <figure><img src="https://padeldosmares.com/wp-content/uploads/2022/03/Padel-Dos-Mares-Escuela-Padel-Galmaq-La-manga.png" alt="Shoes"/></figure>
          <div class="card-body mt-10">
            <h2 class="card-title text-primary">Padel dos mares</h2>
            <p>Web para escuela y club de pádel.</p>
            <div class="card-actions justify-end">
              <a class="btn btn-primary" href="https://padeldosmares.com/" target="_blank">Visitar página</a>
            </div>
          </div>
        </div>
        <div class="card bg-base-100 shadow-xl">
          <figure><img src="https://i.ytimg.com/vi/pkr6S3HBePI/maxresdefault.jpg" alt="Shoes"/></figure>
          <div class="card-body">
            <h2 class="card-title text-primary">Barbería B7</h2>
            <p>Web barbería y tienda online.</p>
            <div class="card-actions justify-end">
              <a class="btn btn-primary" href="https://b7barbershop.com/" target="_blank">Visitar página</a>
            </div>
          </div>
        </div>
        <div class="card bg-base-100 shadow-xl">
          <figure><img src="https://abogados-de-madrid.net/wp-content/uploads/diferencia-entre-abogado-y-letrado-e1558533981872-1024x489.jpg" alt="Shoes"/></figure>
          <div class="card-body mt-6">
            <h2 class="card-title text-primary">Bernabé agobados</h2>
            <p>Web Bernabé abogados.</p>
            <div class="card-actions justify-end">
              <a class="btn btn-primary" href="https://www.bernabeabogados.com/" target="_blank">Visitar página</a>
            </div>
          </div>
        </div>
    </div>
</div>


</template>

<script>
  export default {
    data() {
      return {
        activetab: 1,
        allItems: [
          {
            src: "https://padelfy.net/",
            thumb: require("../assets/img/cara1.jpg"),
          },
          {
            src: require("../assets/img/portfolio/2.jpg"),
            thumb: require("../assets/img/portfolio/2.jpg"),
          },
          {
            src: "https://youtu.be/ZOoVOfieAF8",
            thumb: require("../assets/img/portfolio/4.jpg"),
          },
          {
            src: require("../assets/img/portfolio/3.jpg"),
            thumb: require("../assets/img/portfolio/3.jpg"),
          },
        ],
        vimeoItems: [
          {
            src: "https://vimeo.com/246115326",
            thumb: require("../assets/img/portfolio/4.jpg"),
          },
          {
            src: "https://vimeo.com/43338103",
            thumb: require("../assets/img/portfolio/5.jpg"),
          },
        ],
        youtubeItems: [
          {
            src: "https://youtu.be/ZOoVOfieAF8",
            thumb: require("../assets/img/portfolio/2.jpg"),
          },
          {
            src: "https://youtu.be/ZOoVOfieAF8",
            thumb: require("../assets/img/portfolio/3.jpg"),
          },
        ],
        mixItems: [
          {
            src: "https://vimeo.com/43338103",
            thumb: require("../assets/img/portfolio/5.jpg"),
          },
          {
            src: "https://youtu.be/ZOoVOfieAF8",
            thumb: require("../assets/img/portfolio/4.jpg"),
          },
          {
            src: require("../assets/img/portfolio/3.jpg"),
            thumb: require("../assets/img/portfolio/3.jpg"),
          },
        ],
        galleryItems: [
          {
            src: require("../assets/img/portfolio/2.jpg"),
            thumb: require("../assets/img/portfolio/2.jpg"),
          },
          {
            src: require("../assets/img/portfolio/3.jpg"),
            thumb: require("../assets/img/portfolio/3.jpg"),
          },
          {
            src: require("../assets/img/portfolio/4.jpg"),
            thumb: require("../assets/img/portfolio/4.jpg"),
          },
          {
            src: require("../assets/img/portfolio/5.jpg"),
            thumb: require("../assets/img/portfolio/5.jpg"),
          },
        ],
        index: null,
        lastid: "vue",
      };
    },
    methods:{
      view(id){
        if(id !== this.lastid){
          document.querySelector(`#${id}`).classList.remove("hidden")
          document.querySelector(`#${id}buttom`).classList.add("tab-active")
          try {
            document.querySelector(`#${this.lastid}`).classList.add("hidden")
            document.querySelector(`#${this.lastid}buttom`).classList.remove("tab-active")
            this.lastid = id
          } catch (error) {
            this.lastid = id
          }
        }
      }
    }
  };
</script>

<style lang="scss" scoped></style>
