<template>
  <div>
    <!-- Start leftpart -->
    <div class="leftpart">
      <div class="leftpart_inner">
        <div class="font-extrabold text-xl text-black xl:mb-10">
          <div class="flex flex-row items-center">
            <div class="avatar">
              <div class="w-16 rounded-full ">
                <img src="../assets/img/dmartin.png" />
              </div>
            </div>
            <p class="ml-2">CV Daniel Martín</p>
          </div>
        </div>
        <!-- End .logo -->
        <div class="flex flex-row xl:flex-col xl:mb-10">
          <a 
          class="cursor-pointer hover:bg-gray-200 mb-1"
            v-on:click="activetab = 1"
            :class="[activetab === 1 ? 'active' : '']"
          >
            <img
              class="svg block xl:hidden m-1"
              src="../assets/img/svg/home-run.svg"
              alt="homerun"
            />
            <span id = "iniciobuttom" class="menu_content hidden xl:block">Inicio</span></a
          >
          <!-- End Home -->
          <a
            class="cursor-pointer hover:bg-gray-200 mb-1"
            v-on:click="activetab = 2"
            :class="[activetab === 2 ? 'active' : '']"
          >
            <img class="svg block xl:hidden m-1" src="../assets/img/svg/avatar.svg" alt="avatar" />
            <span id = "aboutbuttom" class="menu_content hidden xl:block">Sobre mi</span></a
          >
          <!-- End About -->
          <a
            class="cursor-pointer hover:bg-gray-200 mb-1"
            v-on:click="activetab = 3"
            :class="[activetab === 3 ? 'active' : '']"
          >
            <img
              class="svg block xl:hidden m-1"
              src="../assets/img/svg/briefcase.svg"
              alt="briefcase"
            />
            <span id = "portfoliobuttom" class="menu_content hidden xl:block">Portfolio</span></a
          >
          <!-- End Portfolio -->

          <!-- End News -->
          <a
            class="cursor-pointer hover:bg-gray-200 mb-1"
            v-on:click="activetab = 5"
            :class="[activetab === 5 ? 'active' : '']"
          >
            <img class="svg block xl:hidden m-1" src="../assets/img/svg/mail.svg" alt="mail" />
            <span id = "contactbuttom" class="menu_content hidden xl:block"> Contacto</span></a
          >
          <!-- End Contact -->
        </div>
        <div class="copyright">
          <p>
            &copy; {{ new Date().getFullYear() }} DM VueJS<br />Created by
            <a
              href="https://themeforest.net/user/ib-themes"
              target="_blank"
              rel="noreferrer"
            >
              Daniel Martín Díaz
            </a>
          </p>
        </div>
        <!-- End copyright -->
      </div>
    </div>
    <!-- End leftpart -->

    <!-- Start rightpart -->
    <div class="rightpart">
      <div class="rightpart_in">
        <div class="tokyo_tm_section">
          <div class="container">
            <div v-if="activetab === 1" class="tabcontent">
              <Home />
            </div>
            <!-- End Home tabcontent -->
            <div v-if="activetab === 2" class="tabcontent">
              <About />
            </div>
            <!-- End About tabcontent  -->
            <div v-if="activetab === 3" class="tabcontent">
              <Portfolio />
            </div>
            <!-- End Portfolio tabcontent -->
            <div v-if="activetab === 4" class="tabcontent">
              <News />
            </div>
            <!-- End News tabcontent -->
            <div v-if="activetab === 5" class="tabcontent">
              <Contact />
            </div>
            <!-- End Contact tabcontent -->
          </div>
        </div>
      </div>
    </div>
    <!-- End rightpart -->
  </div>
</template>

<script>
  import Home from "../components/Home";
  import About from "../components/About";
  import Portfolio from "../components/Portfolio";
  import News from "../components/News";
  import Contact from "../components/Contact";
  export default {
    components: {
      Home,
      About,
      Portfolio,
      News,
      Contact,
    },
    data() {
      return {
        activetab: 1,
      };
    },
    methods:{
      clickMenu(id){
        console.log(id)
      }
    }
  };
</script>

<style lang="scss" scoped></style>
